/* eslint-disable array-callback-return */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import HandlerDataInfos from "../../components/HandleDataInfo";
import Header from "../../components/Header";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";
import { handleSerasaScore } from "../redsimulation/createConsulting";

const regexCEP = /^(\d{5})(\d{3})$/;
const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;


const DataInfo = ({
  cpfcnpj,
  tokenSerasa,
  idConsult,
  userId,
  isClient,
  clientId,
}) => {
  const [count, setCount] = useState(2);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Limpar o timer quando o componente for desmontado
    return () => clearInterval(timer);
  }, []);

  return (
    <Box>
      <HandlerDataInfos
        cpfcnpj={cpfcnpj}
        idConsult={idConsult}
        tokenBrain={tokenSerasa}
        isClient={isClient}
        clientId={clientId}
        userId={userId}
      />
    </Box>
  );
};


const CreateUser = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { cookies } = useCookiesLogin();
  const { id } = cookies.BRAUM_TOKEN;

  // helpers
  const [dataInfo, setDataInfo] = useState(false);

  const [click, setClick] = useState(false);

  const [tokenSerasa, setTokenSerasa] = useState("");
  const [cpfcnpj, setCpfCnpj] = useState("");

  const [brainAuth, setBrainAuth] = useState("");
  const [idConsult, setIdConsult] = useState("");
  const [documentClient, setdocumentClient] = useState("");

  const [algorithm, setAlgorithm] = useState("");
  const [isClient, setIsClient] = useState(false);
  const [clientId, setClientId] = useState("");
  const [bussinesId, setBussinesId] = useState("");

  const [consultaRealizada, setConsultaRealizada] = useState(false);
  const [showInvalidDocumentAlert, setShowInvalidDocumentAlert] = useState(false);
  const [isCpfCnpjLabelBlocked, setIsCpfCnpjLabelBlocked] = useState(false);

  const [handleRefresh, setHandleRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => { }, [click]);


  const navigate = useNavigate();
  const navigateToSucess = () =>
    navigate("/app/successclient", {
      state: {
        message: "Cliente cadastrado com sucesso!",
      },
    });

  async function handleSerasa(documentClient) {
    const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
      method: "GET",
    });

    const returnToken = await authSerasa.json();
    setTokenSerasa(returnToken["token"]);


    let body = JSON.stringify({
      document: documentClient,
      user_id: id,
      token_brain: returnToken["token"],
    });

    const createConsulting = await fetch(`${HOST_SINGED}/brain/basicinfo/id`, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await createConsulting.json();
    
    setIdConsult(data._id);
    setDataInfo(true);
  }

  useEffect(() => {
    document.title = 'Cadastro de Cliente';
  }, []);

  useEffect(() => { }, [documentClient]);

  const verificationCPFCNPJ = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    return cleanedValue.length === 0;
  };


  return (
    <>
      <Box m="20px">
        <ToastContainer />
        <Header
          title="Cadastro de Cliente"
          subtitle="Cadastre clientes em nossa plataforma para criar simulações"
        />
        <Formik initialValues={initialValues} validationSchema={valuesSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <form
                onSubmit={async (e) => {
                  handleSubmit(e);
                  setIsLoading(true);

                  if (verificationCPFCNPJ(values.cpfcnpj)) {
                    toast.info("CPF/CNPJ está vazio!", { theme: "dark" });
                    setIsLoading(false);
                    return;
                  } else {
                    try {
                      await handleSerasa(values.cpfcnpj.replace(/\D/g, ""));
                      setShowInvalidDocumentAlert(false);
                      setConsultaRealizada(true);
                    } catch (e) {
                      setShowInvalidDocumentAlert(true);
                      toast.error("Documento inválido!", { theme: "dark" });
                      window.location.reload();
                    }

                    if (values.cpfcnpj.replace(/\D/g, "").length === 11) {


                      const consult_id = await handleSerasaScore(
                        values.cpfcnpj.replace(/\D/g, ""),
                        tokenSerasa,
                        id
                      );
                      setIsLoading(false);
                      setIdConsult(consult_id._id);
                    }

                    setCpfCnpj(values.cpfcnpj.replace(/\D/g, ""));
                    setHandleRefresh(true);
                    setIsLoading(false);
                  }
                }}
              >
                <Typography
                  variant="h3"
                  color={colors.grey[300]}
                  marginBottom={5}
                >
                  CPF/CNPJ
                </Typography>
                <Box
                  display="grid"
                  gap="30px"
                  marginBottom={3}
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label={isCpfCnpjLabelBlocked ? "CPF/CNPJ Bloqueado" : "CPF/CNPJ"}
                    color="secondary"
                    inputProps={{ maxLength: 18 }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.cpfcnpj.replace(regexCPF, "$1.$2.$3-$4")}
                    name="cpfcnpj"
                    error={!!touched.cpfcnpj && !!errors.cpfcnpj}
                    helperText={touched.cpfcnpj && errors.cpfcnpj}
                    sx={{ gridColumn: "span 2" }}
                    disabled={consultaRealizada || isCpfCnpjLabelBlocked}
                  />
                  </Box>
                  {handleRefresh === true ? (
                    <Button
                      type="submit"
                      onClick={async () => {
                        setIsLoading(true);
                        setConsultaRealizada(false);

                        if (!isLoading) {
                          setIsCpfCnpjLabelBlocked(true);
                          window.location.reload();
                        }
                      }}
                      color="secondary"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        "Nova Consulta"
                      )}
                    </Button>
                  ) : (
                    <Button type="submit" color="secondary" variant="contained">
                      Consultar cliente
                    </Button>
                  )}
              </form>
            </>
          )}
        </Formik>

        {dataInfo === true && isClient === false ? (
          <>
            <DataInfo
              clientId={clientId}
              cpfcnpj={cpfcnpj}
              idConsult={idConsult}
              isClient={isClient}
              tokenSerasa={tokenSerasa}
              userId={id}
              key={clientId}
            />
          </>) : null}

        {dataInfo === false && isClient === true ? (
          <>
            <HandlerDataInfos
              cpfcnpj={cpfcnpj}
              idConsult={idConsult}
              tokenBrain={tokenSerasa}
              isClient={isClient}
              clientId={clientId}
            />
          </>
        ) : null}
      </Box >
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </>
  );
}


const initialValues = {
  cpfcnpj: ""
};

const valuesSchema = yup.object().shape({
  cpfcnpj: yup.string().required("CPF/CNPJ é obrigatório"),
});





export default CreateUser;