import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";

import Braum from "../../services/braum";
import { greenColor } from "../../config/colors";
import { protocolMessages } from "../../config/protocolMessages";
import { tokens } from "../../theme";

import LogoReduto from "./../../assets/logo.png"
import { HOST_BRAUM, HOST_SINGED } from "../../config/hosts";


export function Logo() {
  
  return <img src={LogoReduto} alt="react logo" style={{
    width: 250,
    marginBottom: 50
  }}/>
}

export default function ResetPassword() {
  const [userId, setUserId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateLogin = () => navigate("/");


  const generateRandomPassword = async () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 6;
    let password = "";
    
    for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }

    setNewPassword(password);
  } 

  const sendWhatsappMessage = async () => {
    fetch("https://api.z-api.io/instances/3C66BF5EB02C30C5060A2A5707B8D550/token/4C3204653649EA4579F2FA9D/send-text", {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "client-token": "F3d6adfc0622c4b9da6c56f53e3db64b7S"
        },
        body: JSON.stringify({
            phone: contactPhone,
            message: `Olá, ${name}! Seguindo a sua solicitação de recuperação de senha, aqui está a nova senha de acesso da plataforma da Reduto Capital: ${newPassword}`
        })
    })
    .then((res) => {
        if (res.status === 200) {
            toast.success("Nova senha gerada com sucesso", { theme: "dark" });
        } else {
            toast.error("Algo deu errado. Tente novamente", { theme: "dark" });
        }
    })
  } 

  const sendEmail = async () => {
    fetch(`${HOST_SINGED}/reduto/resetpassword`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        },
        body: JSON.stringify({
            email: email,
            password: newPassword,
            name: name
        })
    })
  }
  const handleCheckUserExists = async () => {
    setIsLoading(true);
    
    fetch(`${HOST_BRAUM}/users/checkuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
            username: username
        })
      })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
            toast.warning("Usuário não encontrado", { theme: "dark" });
          }
      })
      .then((data) => {
        setUserId(data.id);
        setContactPhone("55" + data.phone.replace(/\D/g, ""));
        setEmail(data.email);
        setName(data.name);
        setIsLoading(false);
      });
    }

    const handleResetPassword = async () => {
        handleCheckUserExists();

        if (userId) {
            await generateRandomPassword();

            fetch(`${HOST_BRAUM}/users/access/${userId}`, {
                method: "PATCH",
                headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                },
                body: JSON.stringify({
                    password: newPassword,
                    isFirstAccess: true
                })
            })
            .then((res) => {
                if (res.status === 200) {
                    sendWhatsappMessage();
                    sendEmail();
                } else {
                    toast.warning("Erro ao recuperar a sennha", { theme: "dark" });
                    }
                })
        }
        
        setIsLoading(false);
    } 
    

  useEffect(() => {
    document.title = 'Recuperar senha';
  }, []);

  return (
    <div>
    <form>
    <section className="w-full sm:fixed justify-center items-center bg-gradient-to-r from-[#0e0d0dee] from-% via-[#090808] via-90% to-[#000000]">
      <ToastContainer />
      <section className="w-full h-screen justify-center rounded-md sm:rounded-none p-[20px] sm:flex sm:flex-col sm:justify-center sm:items-center text-center">
        <div className="w-full pb-[10px] flex flex-col justify-center items-center">
          <Logo />
          <div className="w-full h-full mt-[20px] flex flex-col items-center text-left">
            <div className="w-full  h-[60px] sm:max-w-[400px] border-b-2 border-gray-400 hover:border-sky-500 duration-100">
              <label className="text-[#fafafa] text-lg">Usuário</label>
              <input
                type="text"
                className="w-full bg-[#0f1010] p-2 outline-0 text-[#83d84d] mt-[3px]"
                onChange={(e) => setUsername(e.target.value)}
                id="user"
              />
            </div>
            
            <div className="w-full sm:max-w-[400px] mt-[10px]">
              <button
                className="w-full h-[40px] bg-[#83d84d] rounded-md text-lg font-bold"
                onClick={(event) => {
                  event.preventDefault();
                  handleResetPassword();
                }}
              >
                Recuperar senha
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Modal de carregamento */}
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </section>
    </form>
    </div>
  );
}
