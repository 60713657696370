import { HOST_SINGED } from "../../config/hosts";

export async function handleSerasaScore(document, token, user_id) {
  try {
    const createConsulting = await fetch(
      `${HOST_SINGED}/brain/serasa/id`,
      {
        method: "POST",
        body: JSON.stringify({
          document: document,
          user_id: user_id,
          token_brain: token,
        }),
        headers: {
          "Content-Type": "application/json",
          "user-agent":
            "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.111 Safari/537.36",
        },
      }
    );
    const data = await createConsulting.json();
    console.log("Consulta existente", data);
    return data;
  } catch(e) {
    alert("Erro ao consultar CPF!");
    // window.location.reload();
  }
}
