import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import DataInfo from "../../components/DataInfoSimulations";
import HandlerDataInfos from "../../components/HandleDataInfo";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { handleSerasaScore } from "./createConsulting";

import Header from "../../components/Header";
import { HOST_SINGED } from "../../config/hosts";

const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

const initialValues = {
  cpf: "",
  amount: "",
};

const valuesSchema = yup.object().shape({
  amount: yup.number().required("Valor é obrigatório"),
  cpf: yup.string().required("CPF é obrigatório"),
});

const FormSerasa = ({
  algorithm,
  installments,
  amount,
  simulationUri,
  title,
  noProposal,
}) => {
  const [brainAuth, setBrainAuth] = useState("");
  const [idConsult, setIdConsult] = useState("");
  const [cpf, setCpf] = useState("");
  const { cookies } = useCookiesLogin();
  const { id } = cookies.BRAUM_TOKEN;

  const userID = cookies.BRAUM_TOKEN.id;

  const [dataInfo, setDataInfo] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [handleRefresh, setHandleRefresh] = useState(false);
  const [clientId, setClientId] = useState("");
  const [bussinesId, setBussinesId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const verificationCPFCNPJAndAmount = (cpf) => {
    return cpf.replace(/\D/g, "").length === 0;
  };

  useEffect(() => {
    console.log("Atualizando");
  }, [handleRefresh])
    
  return (
    <Box mt={10}>
      <ToastContainer />
      <Header
        title={`RED SCORE`}
        subtitle="Análises de crédito realizadas pela equipe especializada do Reduto Score."
      />
      <Formik initialValues={initialValues} validationSchema={valuesSchema}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form
            onSubmit={async (event) => {
              setIsLoading(true);
              handleSubmit(event);

              if (verificationCPFCNPJAndAmount(values.cpf)) {
                toast.info("CPF/CNPJ não pode estar vazio", {
                  theme: "dark",
                });
                setIsLoading(false);
              }

              const requestToken = await fetch(`${HOST_SINGED}/auth/serasa`, {
                method: "GET",
              });

              // check user is already exists in database
              const response = await fetch(
                `${HOST_SINGED}/client/${values.cpf.replace(/\D/g, "")}`,
                {
                  method: "GET",
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  if (data.status === false) {
                    setDataInfo(true);
                  }

                  setIsClient(data.status);
                  setBussinesId(data.bussines_id);
                  setClientId(data.client_id);
                })
                .then(async () => {
                  const dataToken = await requestToken.json();

                  if (values.cpf.replace(/\D/g, "").length === 11) {
                    // consultando id
                    const consult_id = await handleSerasaScore(
                      values.cpf.replace(/\D/g, ""),
                      dataToken["token"],
                      userID
                    );

                    if (consult_id == null || consult_id == undefined) {
                      setIsLoading(false);
                      alert(
                        "Não foi possivel realizar a consulta, consulte seu consultor"
                      );
                    }
                    setIdConsult(consult_id._id);
                  }

                  setBrainAuth(dataToken["token"]);
                  setCpf(values.cpf.replace(/\D/g, ""));
                  setHandleRefresh(true);
                  setIsLoading(false);
                });
              console.log(response, "response")
              setIsLoading(false);
            }}
          >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                label="CPF/CNPJ"
                type="text"
                color="secondary"
                value={
                  values.cpf.length === 11
                    ? values.cpf.replace(regexCPF, "$1.$2.$3-$4")
                    : values.cpf.replace(regexCNPJ, "$1.$2.$3/$4-$5")
                }
                onChange={handleChange}
                handleBlur={handleBlur}
                inputProps={{ maxLength: 22 }}
                sx={{ gridColumn: "span 2" }}
                name="cpf"
                error={!!touched.cpf && !!errors.cpf}
                helperText={touched.cpf && errors.cpf}
              />
              <Box>
                {handleRefresh === true ? (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Nova Consulta
                  </Button>
                ) : (
                  <Button type="submit" color="secondary" variant="contained">
                    Consultar cliente
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      {/* COMPONENTE PARA ESPERAR O WEBHOOK BRAIN */}
      {dataInfo === true && isClient === false ? (
        <>
          <DataInfo
            algorithm={algorithm}
            amount={amount}
            clientId={userID}
            cpf={cpf}
            idConsult={idConsult}
            isClient={isClient}
            tokenSerasa={brainAuth}
            userId={id}
            key={userID}
            installments={installments}
            simulationUri={simulationUri}
            title={title}
            noProposal={noProposal}
          />
        </>
      ) : null}

      {/* COMPONENTE RETORNA DIRETO O ID CADASTRADO NA BASE DE DADOS */}
      {dataInfo === false && isClient === true ? (
        <>
          <HandlerDataInfos
            cpfcnpj={cpf}
            idConsult={idConsult}
            tokenBrain={brainAuth}
            algorithm={algorithm}
            isClient={isClient}
            amount={amount}
            clientId={userID}
            installments={installments}
            simulationUri={simulationUri}
            title={title}
            noProposal={noProposal}
          />
        </>
      ) : null}

      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </Box>
  );
};

export default FormSerasa;
