import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Backdrop, Box, CircularProgress, IconButton, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BarChart from "../../components/BarChart";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import StatBox from "../../components/StatBox";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";

const Dashboard = () => {
  const { cookies } = useCookiesLogin();
  const response = cookies.BRAUM_TOKEN || {};
  const role = response.role;
  let proposal_url;
  let client_url;

  const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
  const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [client,setClients] = useState(0);
  const [proposal, setProposals] = useState([]);
  const [approvedProposals, setApprovedProposals] = useState([]);
  const [rejectedProposals, setRejectedProposals] = useState([]);
  const [openProposals, setOpenProposals] = useState([]);
  const [finishedProposals, setFinishedProposals] = useState([]);
  const [valueApprovedProposals, setValueApprovedProposals] = useState([0]);
  const [valueRejectedProposals, setValueRejectedProposals] = useState([]);
  const [valueOpenProposals, setValueOpenProposals] = useState([]);
  const [valueFinishedProposals, setValueFinishedProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [open, setOpen] = useState({});

  function handleStatusProposals(data) {
    // eslint-disable-next-line no-unused-expressions
    const aprPro = data.filter(proposal => proposal.fields.status === "APPROVED"); 
    const rejPro = data.filter(proposal => proposal.fields.status === "REJECTED"); 
    const opnPro = data.filter(proposal => proposal.fields.status === "CREATED");
    const finPro = data.filter(proposal => proposal.fields.status === "FINISHED"); 

    setApprovedProposals(aprPro);
    setRejectedProposals(rejPro);
    setOpenProposals(opnPro);
    setFinishedProposals(finPro);
  }

  function handleTotalValueProposals() {
    const valueAprPro =  approvedProposals.reduce((accumulator, p) => {
            return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    const valueRejPro = rejectedProposals.reduce((accumulator, p) => {
        return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    const valueOpnPro = openProposals.reduce((accumulator, p) => {
        return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    const valueFinPro = finishedProposals.reduce((accumulator, p) => {
        return accumulator + parseFloat(p.fields.amount_total);
    }, 0);

    setValueApprovedProposals(valueAprPro);
    setValueRejectedProposals(valueRejPro);
    setValueOpenProposals(valueOpnPro);
    setValueFinishedProposals(valueFinPro);
    setIsLoading(false);
  }

  const renderTableRows = (data) => {
    return (
      <>
        <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
        >
                  Receita por revenda
        </Typography>

        {data.map((row) => (
            <>
            <TableRow
              key={row.pk}
              onClick={() => {
                setOpen((prevState) => {
                  return { ...prevState, [row.pk]: !open[row.pk] }
                });
              }}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.business_name.toUpperCase()}
              </TableCell>
              <TableCell align="left">{parseFloat(row.total_amount).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2
                    })}</TableCell>
            </TableRow>
          </>
        ))}
      </>
    )
  };

  useEffect(() => {
    setIsLoading(true);
    switch (role) {
      case "admin":
        proposal_url = `${HOST_SINGED}/reduto/proposal/all`;
        client_url = `${HOST_SINGED}/reduto/client/all`;
        break;
      case "consultant":
        const business_id = response.bussines_id;
        proposal_url = `${HOST_SINGED}/reduto/proposal/business/` + business_id;
        client_url = `${HOST_SINGED}/reduto/business/clients/` + business_id;
        break;
      default:
          console.log("INVALID");
    }

    fetch(proposal_url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        setProposals(data);
        handleStatusProposals(data);
        handleTotalValueProposals();
      });
    
    fetch(client_url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        setClients(data.length);
      });
  }, [valueApprovedProposals]);

  useEffect(() => {
    document.title = 'Home';
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${HOST_SINGED}/reduto/revenue/business`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setBusinessList(data);
        setIsLoading(false);
      });
  }, []);

  
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Seja bem-vindo ao dashboard" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Propostas na plataforma
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {parseFloat(valueApprovedProposals + valueRejectedProposals + valueOpenProposals + valueFinishedProposals).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2
                })}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="350px" m="20px 0 0 0">
            {/* <LineChart isDashboard={true} data={[approvedProposals, rejectedProposals, openProposals]} /> */}
            <PieChart isDashboard={true} data={[approvedProposals, rejectedProposals, openProposals, finishedProposals]} />
            <BarChart isDashboard={true} data={[approvedProposals, rejectedProposals, openProposals, finishedProposals]}/>
          </Box>
        </Box>

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m="0 0 10px 0"
            p="20px"
          >
            <StatBox
              title={proposal.length}
              subtitle="Propostas"
              progress={approvedProposals.length / proposal.length}
              increase={isNaN(parseFloat((approvedProposals.length  / proposal.length) * 100).toFixed(2)) ? 0 + "% aprovadas" : parseFloat((approvedProposals.length  / proposal.length) * 100).toFixed(2) + "% aprovadas"}
              icon={
                <PointOfSaleIcon
                  sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m="0 0 10px 0"
            p="20px"
          >
            <StatBox
              title={client}
              subtitle="Clientes cadastrados"
              progress="0.5"
              increase="0%"
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {role === "admin" ? <Box
             gridColumn="span 6"
             backgroundColor={colors.primary[400]}
             display="flex"
             alignItems="center"
             justifyContent="center"
          >
            {/* <TableBody>{renderTableRows(businessList)}</TableBody> */}
          </Box> : null}
      </Box>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </Box>
  );
};

export default Dashboard;
