import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useCookiesLogin } from "../../config/cookies";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Collapse,
  Typography,
  Grid
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { greenColor } from "../../config/colors";

import { HOST_SINGED } from "../../config/hosts";

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;


const BusinessTable = () => {
  const { cookies } = useCookiesLogin();
  const response = cookies.BRAUM_TOKEN || {};

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [consulta, setConsulta] = useState("");
  const [businessList, setBusinessList] = useState([]);

  const [open, setOpen] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${HOST_SINGED}/reduto/business/all`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        setBusinessList(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = "Empresas";
  }, []);

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? (
        <ArrowUpwardIcon color="white" />
      ) : (
        <ArrowDownwardIcon color="white" />
      );
    }
    return null;
  };

  const filteredBusiness = businessList.filter((b) =>
    b.fields.official_name.toLowerCase().includes(consulta.toLowerCase())
  );

  const sortedBusiness = [...filteredBusiness].sort((a, b) => {
    if (sortBy === "official_name") {
      return sortOrder === "asc"
        ? a.fields.official_name.localeCompare(b.fields.official_name)
        : b.fields.official_name.localeCompare(a.fields.official_name);
    } else if (sortBy === "official_name") {
      return sortOrder === "asc"
        ? a.fields.official_name.localeCompare(b.fields.official_name)
        : b.fields.official_name.localeCompare(a.fields.official_name);
    } else if (sortBy === "score") {
      return sortOrder === "asc"
        ? parseFloat(a.fields.score) - parseFloat(b.fields.score)
        : parseFloat(b.fields.score) - parseFloat(a.fields.score);
    } else if (sortBy === "cnpj") {
      return sortOrder === "asc"
        ? parseFloat(a.fields.cnpj) - parseFloat(b.fields.cnpj)
        : parseFloat(b.fields.cnpj) - parseFloat(a.fields.cnpj);
    }
    return 0;
  });

  const renderTableRows = (data) => {
    return data.map((row) => (
      <>
        <TableRow
          key={row.pk}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen((prevState) => {
                    return { ...prevState, [row.pk]: !open[row.pk] }
                  });
                }}
              >
                {open[row.pk] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.fields.trade_name === "" ? row.fields.official_name.toUpperCase() : row.fields.trade_name.toUpperCase()}
          </TableCell>
          <TableCell align="left">{row.fields.cnpj.replace(regexCNPJ, "$1.$2.$3/$4-$5")}</TableCell>
          <TableCell align="left">{row.fields.tax_id_status}</TableCell>
        </TableRow>
        <TableRow>
          <Collapse in={open[row.pk]} timeout="auto" unmountOnExit> 
            <Grid container spacing={4} p={3}>
            <Grid item xs={3}>
                <Typography variant="h5" color={greenColor}>
                  Cidade
                </Typography>
                <Typography>
                  {row.fields.city}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h5" color={greenColor}>
                  Email
                </Typography>
                <Typography>
                  {row.fields.email}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h5" color={greenColor}>
                  Telefone
                </Typography>
                <Typography>
                  {row.fields.phone.split(',').map((tel) => tel.replace(regexPhone, "($1) $2 $3-$4 | "))}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </TableRow>
      </>
    ));
  };

  const limparFiltro = () => {
    setConsulta("");
    setSortBy("");
    setSortOrder("asc");
  };

  return (
    <>
      <Box m="20px">
        <Header
          title="Empresas"
          subtitle="Aqui você pode encontrar informações sobre as revendas cadastradas."
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Pesquisar por nome"
            variant="outlined"
            fullWidth
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={limparFiltro}
            style={{ marginLeft: "10px" }}
          >
            Limpar Filtro
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>

                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("official_name")}
                    startIcon={getSortIcon("official_name")}
                    sx={{ color: "white" }}
                  >
                    Nome da revenda
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("cnpj")}
                    startIcon={getSortIcon("cnpj")}
                    sx={{ color: "white" }}
                  >
                    CNPJ
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => handleSortChange("tax_id_status")}
                    startIcon={getSortIcon("tax_id_status")}
                    sx={{ color: "white" }}
                  >
                    STATUS
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows(sortedBusiness)}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </>
  );
};

export default BusinessTable;
