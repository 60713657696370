import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";

import Braum from "../../services/braum";
import { greenColor } from "../../config/colors";
import { protocolMessages } from "../../config/protocolMessages";
import { tokens } from "../../theme";

import LogoReduto from "./../../assets/logo.png"
import { Link } from "react-router-dom";


export function Logo() {
  
  return <img src={LogoReduto} alt="react logo" style={{
    width: 250,
    marginBottom: 50
  }}/>
}

export default function ComponentLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { cookies, setCookies } = useCookiesLogin();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const navigateHome = () => navigate("app");

  // Classe responsável pela conexão do Braum:
  const braum = new Braum(username, password);

  // Login manual:
  const handleLogin = async () => {
    setIsLoading(true);
    const response = await braum.login();
    const tokenCookie = response.data;

    if (response.status === 200) {
      const { status } = await braum.store();

      if (status === 200) {
        setCookies("BRAUM_TOKEN", tokenCookie);

        setIsLoading(false);

        navigateHome();
      }
    } else {
      const statusError = response.status;
      const message = protocolMessages[statusError] || "Estamos em manutenção";
      toast.warning(message, { theme: "dark" });
    }
    setIsLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  useEffect(() => {
    document.title = 'Login';
  }, []);

  return (
    <div>
    <form>
    <section className="w-full sm:fixed justify-center items-center bg-gradient-to-r from-[#0e0d0dee] from-% via-[#090808] via-90% to-[#000000]">
      <ToastContainer />
      <section className="w-full h-screen justify-center rounded-md sm:rounded-none p-[20px] sm:flex sm:flex-col sm:justify-center sm:items-center text-center">
        <div className="w-full pb-[10px] flex flex-col justify-center items-center">
          <Logo />
          <div className="w-full h-full mt-[20px] flex flex-col items-center text-left">
            <div className="w-full  h-[60px] sm:max-w-[400px] border-b-2 border-gray-400 hover:border-sky-500 duration-100">
              <label className="text-[#fafafa] text-lg">Usuário</label>
              <input
                type="text"
                className="w-full bg-[#0f1010] p-2 outline-0 text-[#83d84d] mt-[3px]"
                onChange={(e) => setUsername(e.target.value)}
                id="loginUser"
              />
            </div>
            <div className="w-full h-[60px] sm:max-w-[400px] border-b-2 border-gray-400 hover:border-sky-500 duration-100 mt-[15px]">
              <label className="text-[#fafafa] text-lg">Senha</label>
              <div className="w-full mt-[3px] flex flex-row">
                <input
                  type="password"
                  className="w-full bg-[#0f1010] p-2 outline-0 text-[#83d84d]"
                  onChange={(e) => setPassword(e.target.value)}
                  id="loginPassword"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="w-full sm:max-w-[400px] mt-[10px]">
              <div className="w-full h-[60px] flex flex-col sm:flex-row sm:justify-between">
                <div className="max-w-[50%] h-[30px] flex flex-row items-center cursor-pointer">
                  <Link to="resetPassword" className="text-[#C0C0C0] hover:text-[#0EA5E9] text-sm">
                    Esqueceu a senha?
                  </Link>
                </div>
              </div>
              <button
                className="w-full h-[40px] bg-[#83d84d] rounded-md text-lg font-bold"
                onClick={(event) => {
                  event.preventDefault();
                  handleLogin();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Modal de carregamento */}
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" sx={{ color: greenColor }} />
      </Backdrop>
    </section>
    </form>
    </div>
  );
}
