import { Add } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Header from "../../components/Header";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { getCep } from "../../services/cep";
import { tokens } from "../../theme";

const columns = [
  {
    id: "Código",
    label: "Código",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("pt-BR"),
  },
  {
    id: "activities",
    label: "Descrição Atividade",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("pt-BR"),
  },
];

const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexCEP = /^(\d{5})(\d{3})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;


const DataInfo = ({ cnpj, tokenSerasa, idConsult }) => {
  const [count, setCount] = useState(10);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [displayNumberHome, setDisplayNumberHome] = useState(false);
  const [officialName, setOfficialName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [taxRegime, setTaxRegime] = useState("");
  const [taxIdStatus, setTaxIdStatus] = useState("");
  const [taxIdStatusDate, setTaxIdStatusDate] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [risk, setRisk] = useState("");
  const [operation, setOperation] = useState("");
  const [natureBussines, setNatureBussines] = useState("");
  const [sizeCompany, setSizeCompany] = useState("");
  const [activities, setActivities] = useState([]);
  const [hasNumber, setHasNumber] = useState(false);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [phone, setPhone] = useState("");
  const [loadingButton, setLoadingButton] = useState();

  const [phoneList, setPhoneList] = useState([]);

  const [controlFetch, setControlFetch] = useState(false);
  useEffect(() => { }, [officialName]);

  const navigate = useNavigate();

  const navigateToSucess = () =>
    navigate("/app/successbussines", {
      state: {
        message: "Cadastro de Revenda com sucesso!",
      },
    });

  async function handleGetInfo() {
    const request = await fetch(`${HOST_SINGED}/brain/info/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        id_consult: idConsult,
        token_brain: tokenSerasa,
      }),
    });

    const response = await request.json();


    // format timestamp to DD/MM/YYYY
    let timestamp = response["datasets"][0]["results"][0]["basicData"]["taxIdStatusRegistrationDate"];

    let date = new Date(timestamp);

    // Extract the components of the date
    var day = date.getDate();
    var month = date.getMonth() + 1; // Months are zero-based, so we add 1
    var year = date.getFullYear();

    var formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    setOfficialName(
      response["datasets"][0]["results"][0]["basicData"]["officialName"]
    );
    setTradeName(
      response["datasets"][0]["results"][0]["basicData"]["tradeName"]
    );
    setTaxRegime(
      response["datasets"][0]["results"][0]["basicData"]["taxRegime"]
    );
    setTaxIdStatus(
      response["datasets"][0]["results"][0]["basicData"]["taxIdStatus"]
    );
    setTaxIdStatusDate(
      formattedDate
    );
    setActivities(
      response["datasets"][0]["results"][0]["basicData"]["activities"]
    );
    setTaxIdNumber(
      response["datasets"][0]["results"][0]["basicData"]["taxIdNumber"]
    );
    setRisk(
      response["datasets"][0]["results"][0]["basicData"]["riskScreening"]
    );
    setOperation(
      response["datasets"][0]["results"][0]["basicData"]["operabilityIndicator"]
    );
    setNatureBussines(
      response["datasets"][0]["results"][0]["basicData"]["legalNature"][
      "Activity"
      ]
    );
    setSizeCompany(
      response["datasets"][0]["results"][0]["basicData"]["companySize"]
    );
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);


    // Limpar o timer quando o componente for desmontado
    return () => {
      clearInterval(timer);
      // realizar inserção do state
      setTimeout(() => {
        setControlFetch(true)
      }, 10000);
    };
  }, []);

  useEffect(() => {
    handleGetInfo();
  }, [controlFetch])

  return (
    <Box>
      {loadingButton === true ? (
        <>
          <Backdrop open={loadingButton} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" sx={{ color: greenColor }} />
          </Backdrop>
        </>
      ) : (
        <>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Razão Social
                </Typography>
                <Typography>{officialName ? officialName : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Nome Comercial
                </Typography>
                <Typography>
                  {tradeName === "" ? "Não Informado" : tradeName}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Regime Tributário
                </Typography>
                <Typography>{taxRegime ? taxRegime : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  STATUS
                </Typography>

                {taxIdStatus === "ATIVA" ? (
                  <Typography sx={{ fontWeight: "600" }} color={"#83d84d"}>
                    {taxIdStatus}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  A empresa teve seu início
                </Typography>
                <Typography>{taxIdStatusDate ? taxIdStatusDate : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Natureza Jurídica
                </Typography>
                <Typography>{natureBussines ? natureBussines : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Risco
                </Typography>
                <Typography>{risk ? risk : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  CNPJ
                </Typography>
                <Typography>{taxIdNumber ? taxIdNumber.replace(regexCNPJ, "$1.$2.$3/$4-$5") : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  Operação
                </Typography>
                <Typography>{operation ? operation : "Não informado"}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  marginBottom={2}
                >
                  PORTE / TAMANHO
                </Typography>
                <Typography>{sizeCompany ? sizeCompany : "Não informado"}</Typography>
              </Grid>
            </Grid>
            <Box>
              <Typography
                variant="h2"
                color={colors.grey[300]}
                marginBottom={2}
                marginTop={5}
              >
                Atividades
              </Typography>
              <TableContainer sx={{ maxHeight: 440, paddingX: 10 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((data) => (
                        <TableCell
                          key={data.id}
                          align={data.align}
                          style={{ minWidth: data.minWidth }}
                        >
                          {data.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activities.map((data) => (
                      <TableRow>
                        <TableCell key={data.Code} align="center">
                          {data.Code}
                        </TableCell>
                        <TableCell key={data.Activity} align="left">
                          {data.Activity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box mt={20}>
            <ToastContainer />
            <Typography variant="h2" color={colors.grey[300]} marginBottom={5}>
              Dados Plataforma
            </Typography>
            <Formik
              initialValues={initialValuesLocale}
              validationSchema={localeSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form
                  onSubmit={async (event) => {
                    handleSubmit(event);
                    const flist = phoneList.map((tel) => tel.replace(/[^\w]/gi, ""));

                      setLoadingButton(true);
                      
                    const request = await fetch(
                      `${HOST_SINGED}/business/`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          cnpj: cnpj,
                          cep: values.cep,
                          city: city ? city : "Não informado",
                          email: values.email,
                          name: tradeName === "" ? officialName : tradeName,
                          phone: flist.toString(),
                          brain_id: idConsult,
                          token_brain: tokenSerasa,
                        }),
                      }
                    )

                    if (request.status !== 201) {
                      setLoadingButton(false);
                      return toast.error("Erro ao cadastrar revenda! Tente novamente ou entre em contato com o suporte", { theme: "dark" });
                    } else {
                      navigateToSucess();
                    }
                  }}
                >
                  <Box
                    display="grid"
                    gap="30px"
                    marginBottom={10}
                    gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="email"
                      label="Email"
                      placeholder="Email principal para logar na plataforma."
                      color="secondary"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Telefone"
                      color="secondary"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone.replace(regexPhone, "($1) $2 $3-$4")}
                      name="phone"
                      error={!!touched.phone && !!errors.phone && phoneList.length === 0}
                      helperText={touched.phone && errors.phone && phoneList.length === 0}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <Add
                      sx={{
                        color: "#1e1e1e",
                        backgroundColor: "#83d84d",
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px"
                      }}
                      onClick={() => {
                        phoneList.push(values.phone.replace(/\Dg/, ""));
                        values.phone = "";
                      }}
                    />
                    {phoneList.length > 0 ? (
                      <Box>
                        <Typography>
                          Telefones cadastrados
                        </Typography>

                        {phoneList.map((tel) =>
                          <Typography> {tel.replace(regexPhone, "($1) $2 $3-$4")} </Typography>
                        )}
                      </Box>
                    ) : null}
                  </Box>

                  <Box>
                      <Typography
                        variant="h2"
                        color={colors.grey[300]}
                        marginBottom={4}
                      >
                        Endereço
                      </Typography>

                      <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                          "& > div": {
                            gridColumn: isNonMobile ? undefined : "span 4",
                          },
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="CEP"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={async (e) => {
                            handleChange(e);
                            if (e.target.value.replace(/\D/g, "").length === 8) {
                              const response = await getCep(
                                e.target.value.replace(/\D/g, "")
                              );
                              setCity(response.data.localidade);
                              setNeighborhood(response.data.bairro);
                              setAddress(response.data.logradouro);
                              setDisplayNumberHome(true);
                            }
                          }}
                          value={values.cep.replace(regexCEP, "$1-$2")}
                          name="cep"
                          error={!!touched.cep && !!errors.cep}
                          helperText={touched.cep && errors.cep}
                          sx={{ gridColumn: "span 2" }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="grid"
                      gap="30px"
                      marginBottom={5}
                      gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                      sx={{
                        "& > div": {
                          gridColumn: isNonMobile ? undefined : "span 4",
                        },
                      }}
                    >
                      <Grid container spacing={2} marginBottom={2} marginTop={5}>
                        <Grid item xs={4} sx={{ marginY: 3 }}>
                          <Typography
                            variant="h4"
                            color={colors.grey[300]}
                            marginBottom={2}
                          >
                            Cidade
                          </Typography>
                          <Typography fontSize={22}>
                            {city ? city : "Não informado"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ marginY: 3 }}>
                          <Typography
                            variant="h4"
                            color={colors.grey[300]}
                            marginBottom={2}
                          >
                            Bairro
                          </Typography>
                          <Typography fontSize={22}>
                            {neighborhood ? neighborhood : "Não informado"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ marginY: 3 }}>
                          <Typography
                            variant="h4"
                            color={colors.grey[300]}
                            marginBottom={2}
                          >
                            Logradouro
                          </Typography>
                          <Typography fontSize={22}>
                            {address ? address : "Não informado"}
                          </Typography>
                        </Grid>
                        {displayNumberHome === true ? (
                          <Grid item xs={4} sx={{ marginY: 3 }}>
                            <Typography
                              variant="h4"
                              color={colors.grey[300]}
                              marginBottom={2}
                            >
                              Número
                            </Typography>
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Número Residência"
                              color="secondary"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={hasNumber}
                              value={values.numberHome}
                              name="numberHome"
                              error={!!touched.numberHome && !!errors.numberHome}
                              helperText={touched.numberHome && errors.numberHome}
                              sx={{ gridColumn: "span 2" }}
                            />

                            <FormControlLabel 
                              label="Não possui número" 
                              control={
                                <Checkbox 
                                  checked={hasNumber}
                                  onChange={(e) => {
                                    setHasNumber(e.target.checked);
                                  }}
                                  sx={{
                                      color: "#1e1e1e",
                                      backgroundColor: "#83d84d",
                                      borderRadius: "10px",
                                      width: "30px",
                                      height: "30px",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                      ":hover": {
                                        color: "#1e1e1e",
                                        backgroundColor: "#83d84d",
                                      }
                                  }}/>
                              }/>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Box>
                    </Box>
                    <Box>
                      {loadingButton === true ? (
                        <Backdrop open={loadingButton} style={{ zIndex: 9999 }}>
                          <CircularProgress color="inherit" sx={{ color: greenColor }} />
                        </Backdrop>
                      ) : (
                        <Button
                          sx={{
                            width: 400,
                            marginY: 0,
                            paddingX: 10,
                            paddingY: 2,
                          }}
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          Cadastrar Revenda
                        </Button>
                      )}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </>
      )}
    </Box>
  );
};

const verificationCNPJ = (cnpj) => {
  return cnpj.replace(/\D/g, "").length === 0;
};

const Form = () => {
  // component config
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { cookies } = useCookiesLogin();
  const { id } = cookies.BRAUM_TOKEN;


  // helpers
  const [dataInfo, setDataInfo] = useState(false);

  const [click, setClick] = useState(false);

  const [tokenSerasa, setTokenSerasa] = useState("");
  const [idConsult, setIdConsult] = useState("649b4ddf0ebed427a87ff087");
  const [cnpj, setCnpj] = useState("");
  const [loadingButton, setLoadingButton] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [consultaRealizada, setConsultaRealizada] = useState(false);
  const [showInvalidDocumentAlert, setShowInvalidDocumentAlert] = useState(false);
  const [isCnpjLabelBlocked, setIsCnpjLabelBlocked] = useState(false);

  const [count, setCount] = useState(10);
  const [controlFetch, setControlFetch] = useState(false);

  const [handleRefresh, setHandleRefresh] = useState(false);

  useEffect(() => { }, [click]);

  async function handleSerasa(document) {
    const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
      method: "GET",
    });

    const tokenSerasa = await authSerasa.json();

    const createConsulting = await fetch(
      `${HOST_SINGED}/brain/basicinfo/id`,
      {
        method: "POST",
        body: JSON.stringify({
          document: document,
          user_id: id,
          token_brain: tokenSerasa["token"],
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await createConsulting.json();

    setTokenSerasa(tokenSerasa["token"]);
    setIdConsult(data._id);
    setDataInfo(true);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);


    // Limpar o timer quando o componente for desmontado
    return () => {
      clearInterval(timer);
      // realizar inserção do state
      setTimeout(() => {
        setControlFetch(true)
      }, 10000);
    };
  }, []);
  
  useEffect(() => {
    document.title = 'Cadastro Empresa';
  }, []);

  return (
    <>
      <Box m="20px">
        <ToastContainer />
        <Header
          title="Cadastro de Empresa"
          subtitle="Cadastros de empresas revendedoras reduto"
        />
        <Formik initialValues={initialValues} validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <form
                onSubmit={async (e) => {
                  handleSubmit(e);
                  setLoadingButton(true);

                  if (verificationCNPJ(values.document)) {
                    toast.info("CNPJ não pode estar vazio", { theme: "dark" });
                    setLoadingButton(false);
                    return;
                  }
                  try {
                    await handleSerasa(values.document.replace(/\D/g, ""));
                    setShowInvalidDocumentAlert(false)
                    setConsultaRealizada(true);
                  } catch (e) {
                    setShowInvalidDocumentAlert(true);
                    toast.error("Documento inválido!", { theme: "dark" });
                  }

                  setCnpj(values.document.replace(/\D/g, ""));
                  setHandleRefresh(true);
                  setLoadingButton(false);
                }}
              >
                <Typography
                  variant="h3"
                  color={colors.grey[300]}
                  marginBottom={5}
                >
                  CNPJ
                </Typography>
                <Box
                  display="grid"
                  gap="30px"
                  marginBottom={3}
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label={isCnpjLabelBlocked ? "CNPJ Bloqueado" : "CNPJ"}
                    color="secondary"
                    inputProps={{ maxLength: 18 }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.document.replace(regexCNPJ, "$1.$2.$3/$4-$5")}
                    name="document"
                    error={!!touched.document && !!errors.document}
                    helperText={touched.document && errors.document}
                    sx={{ gridColumn: "span 2" }}
                    disabled={consultaRealizada || isCnpjLabelBlocked}
                  />
                </Box>
                {handleRefresh === true ? (
                  <Button
                    type="submit"
                    onClick={async () => {
                      setIsLoading(true);
                      setConsultaRealizada(false);

                      if (!isLoading) {
                        setIsCnpjLabelBlocked(true);
                        window.location.reload();
                      }
                    }}
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "Nova Consulta"
                    )}
                  </Button>
                ) : (
                  <Button type="submit" color="secondary" variant="contained">
                    Consultar Empresa
                  </Button>
                )}
              </form>
            </>
          )}
        </Formik>
        {dataInfo === true ? (
          <DataInfo
            colors={colors}
            cnpj={cnpj}
            tokenSerasa={tokenSerasa}
            idConsult={idConsult}
          />
        ) : null}
        <Backdrop open={loadingButton} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box >
    </>
  );
};

const checkoutSchema = yup.object().shape({
  document: yup
    .string()
    .max(19, "CNPJ com mais de 18 caracteres invalido!")
    .required("Obrigatorio"),
});

const initialValues = {
  document: "",
};

const localeSchema = yup.object().shape({
  cep: yup
    .string()
    .max(11, "CEP com mais de 11 caracteres invalido!")
    .required("Campo Obrigatório."),
  city: yup.string().required("Campo Obrigatório."),
  address: yup.string(),
  numberHome: yup.number().positive().label("Número da Residência"),
  email: yup.string().email().required("Campo Obrigatório."),
  phone: yup.string().required("Campo Obrigatório."),
  neighborhood: yup.string(),
});

const initialValuesLocale = {
  cep: "",
  city: "",
  address: "",
  uf: "",
  email: "",
  phone: "",
  neighborhood: "",
};

export default Form;
